<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="row">
            <div class="col-md-3">
              <img class="bc-mof-logo d-block mr-4 mb-3 mb-md-0" src="/static/img/BC_ministry_of_finance_logo.png" alt="BC Ministry of Finance">
            </div>
            <div class="col-auto mr-auto">
              <h2 class="page-title">
                A1 release
              </h2>
              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>


            <div class="col-auto">
              <!-- Drawer toggle and delete package -->
              <div class="d-flex justify-content-between ml-auto">
                <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                  <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                  <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                  Navigate Application
                </button>
                <button type="button" class="btn btn-md btn-danger mb-2">
                  <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                  <span class="d-none d-sm-block">Delete Application</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/questionnaire">Transferees</router-link>
                    <div class="step">
                      <router-link class="step-link" to="/pages/edit-application/part-a-individual">{Individual Transferee Name}</router-link>
                    </div>
                    <div class="step">
                      <router-link class="step-link" to="/pages/edit-application/part-a-corporation">{Corporate Transferee Name}</router-link>
                    </div>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Contact Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Transferors</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Property Description</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/part-e-terms">Terms</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Gross Purchase Price</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Additional Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Principal Residence Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Tax Calculation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">First Time Home Buyers</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/certification">Certification</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachments</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Presentation Copy</a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-8">

              <div class="alert alert-info">
                  <div class="alert-icon">
                    <svg class="icon icon-info"><use xlink:href="/icons/symbol-defs.svg#icon-info"></use></svg>
                  </div>
                  <p>For more information on filling out the Property Transfer Tax Return, refer to the <a href="javascript:void(0)">Property Transfer Tax Return Guide.</a> You may also find the following helpful:</p>
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                      <u>Guide to the First Time Home Buyers' Program</u>
                      <svg class="icon icon-External-link"><use xlink:href="/icons/symbol-defs.svg#icon-External-link"></use></svg>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <u>Newly Built Home Exemption Guide</u>
                        <svg class="icon icon-External-link"><use xlink:href="/icons/symbol-defs.svg#icon-External-link"></use></svg>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <u>Additional Transfer Tax Guide</u>
                        <svg class="icon icon-External-link"><use xlink:href="/icons/symbol-defs.svg#icon-External-link"></use></svg>
                      </a>
                    </li>
                  </ul>
              </div>

              <h2 class="mb-3">Transferees (Purchasers)</h2>
              <p class="lead">
                Use this page to add or remove transferees. Once you have saved a Transferee, you will not be able to edit the information except for name of transferee. Once you add a transferee, you will be adding additional information on the subsequent page.
              </p>

              <!-- Transferee 1 -->
              <div class="mb-4 p-2 p-md-4 bg-medium">

                <form v-on:submit.prevent>
                  <!-- Individual saved -->
                  <template>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <h3 class="mb-0">Transferee 1</h3>
                      <button class="btn btn-md btn-danger">Delete</button>
                    </div>

                    <div class="card mb-4">
                      <div class="card-body">
                        Last Name: <strong>Doe</strong><br>
                        Given Names: <strong>Jane</strong><br
                        >Canadian Citizen or Permanent Resident: <strong>No</strong><br>
                        Claiming BC Provincial Nominee (PN) Exemption: <strong>Yes</strong><br>
                        Exemption Claimed: <strong>None - No exemption claimed</strong>
                      </div>
                    </div>
                  </template>

                  <!-- Corporation saved -->
                  <template>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <h3 class="mb-0">Transferee 2</h3>
                      <button class="btn btn-md btn-danger">Delete</button>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        Name: <strong>ABC Depot Company</strong><br>
                        Transferee Type: <strong>Corporation</strong><br>
                        Exemption Claimed: <strong>18 - Family Farm Corporation</strong>
                      </div>
                    </div>
                  </template>

                  <button class="btn btn-md btn-tertiary mt-3" @click.prevent="transfereeFormOpen = true" v-if="!transfereeFormOpen">+ Add Another Transferee</button>

                  <!-- Select transferee type -->
                  <template v-if="transfereeFormOpen == true">
                    <h3 class="mt-4">Transferee 3</h3>
                    <div class="form-group">
                      <label class="control-label" for="propertySize1">Transferee Type</label>
                      <select class="form-control col-md-6" id="transfereeType" v-model="transfereeType">
                        <option value="0">Select type</option>
                        <option value="1">Individual</option>
                        <option value="2">Corporation</option>
                        <option value="3">Association</option>
                        <option value="4">Financial Institution</option>
                        <option value="5">Government</option>
                        <option value="6">Society</option>
                        <option value="7">Strata Corporation</option>
                        <option value="8">None of the above</option>
                      </select>
                    </div>

                    <template  v-if="transfereeType == 0">
                      <a class="collapse-toggle" href="#collapseExample2" data-toggle="collapse" aria-expanded="false" aria-controls="collapseExample2">
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                        <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                        What is a corporation?
                      </a>
                      <div class="collapse" id="collapseExample2">
                        <div class="card bg-white mt-1">
                          <div class="card-body">
                            <p>Section 29 of the Interpretation Act (RSBC 1996) CHAPTER 238, defines “corporation” to mean an incorporated association, company, society, municipality or other incorporated body, where and however incorporated, and includes a corporation sole other than Her Majesty or the Lieutenant Governor.</p>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>

                  <!-- Individual transferee -->
                  <template v-if="transfereeType == 1">
                    <div class="form-row mt-2">
                      <div class="form-group col-md-6">
                        <label for="lastName">Last Name</label>
                        <input class="form-control" type="text" name="lastName" id="lastName">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="givenName">Given Name(s)</label>
                        <input class="form-control" type="text" name="givenName" id="givenName" >
                      </div>
                    </div>

                    <label class="mt-1">Is the transferee a Canadian citizen or permanent resident?</label>
                    <div class="form-group">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="canadianCitizen" name="canadianCitizen" class="custom-control-input" :value="true" v-model="canadianCitizen">
                        <label class="custom-control-label" for="canadianCitizen">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="canadianCitizen2" name="canadianCitizen" class="custom-control-input" :value="false" v-model="canadianCitizen">
                        <label class="custom-control-label" for="canadianCitizen2">No</label>
                      </div>
                    </div>

                    <template v-if="canadianCitizen">
                      <label>Is the transferee claiming a BC Provincial Nominee (PN) exemption?
                        <a class="text-tertiary collapse-toggle" href="#claimingPNexemptionHelp" data-toggle="collapse" aria-expanded="false" aria-controls="claimingPNexemptionHelp">
                          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                        </a>
                        <div class="collapse" id="claimingPNexemptionHelp">
                          <div class="card bg-secondary my-1">
                            <div class="card-body">
                              A BC PN is a foreign national who holds a nomination certificate issued by the <a href="https://www.welcomebc.ca/immigrate-to-b-c/b-c-provincial-nominee-program" target="_blank" rel="nofollow noopener"> BC Provincial Nominee Program</a>
                            </div>
                          </div>
                        </div>
                      </label>
                      <div class="form-group">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="claimingExemption" name="claimingExemption" class="custom-control-input">
                          <label class="custom-control-label" for="claimingExemption">Yes</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="claimingExemption2" name="claimingExemption" class="custom-control-input">
                          <label class="custom-control-label" for="claimingExemption2">No</label>
                        </div>
                      </div>
                    </template>

                    <div class="form-group">
                      <label for="country">Is the transferee claiming an exemption?
                        <a class="text-tertiary collapse-toggle" href="#claimingExemtionHelp" data-toggle="collapse" aria-expanded="false" aria-controls="claimingExemtionHelp">
                          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                        </a>
                        <div class="collapse" id="claimingExemtionHelp">
                          <div class="card bg-secondary my-1">
                            <div class="card-body">
                              If you are claiming an exemption, then choose an <a href="https://www2.gov.bc.ca/gov/content/taxes/property-taxes/property-transfer-tax/file/tax-return-guide/exemption-codes" target="_blank" rel="nofollow noopener">exemption code</a> from the list now. It is not possible to claim an exemption for a transferee after the transferee has been added.
                            </div>
                          </div>
                        </div>
                      </label>
                      <select class="form-control" name="claimingExeption" id="claimingExeption">
                        <option class="u675_input_option" value="None - No exemption claimed">None - No exemption claimed</option>
                        <option>03 - Transitional Rate of Tax</option>
                        <option>04 - Transitional Rate of Tax</option>
                        <option>05 - Related Individual – Principal Residence</option>
                        <option>06 - Recreational Residence</option>
                        <option>07 - Family Farm</option>
                        <option>08 - Survivorship</option>
                        <option>09 - Executor/Executrix of Estate</option>
                        <option>10 - Subdivision – Multi Lot</option>
                        <option>12 - Trustee / Bankruptcy</option>
                        <option>13 - Vendor's Interest under Agreement for Sale</option>
                        <option>14 - Fee Simple / Agreement for Sale</option>
                        <option>15 - Separation Agreement - Related</option>
                        <option>16 - Changing a Joint Tenant to a Tenancy in Common</option>
                        <option>17 - Lease</option>
                        <option>18 - Family Farm Corporation</option>
                        <option>19 - Escheat or Forfeit to Crown</option>
                        <option>20 - Transfer to Muncipality, Reg. District, etc.</option>
                      </select>
                    </div>

                    <div class="form-actions mt-1 mb-4">
                      <button class="btn btn-md btn-tertiary">Done</button>
                      <button class="btn btn-md btn-link">Cancel</button>
                    </div>
                  </template>

                  <!-- Corporation -->
                  <template v-if="transfereeType > 1 && transfereeType < 8">
                    <div class="form-group">
                      <label for="legalName">Legal Corporation Name</label>
                      <input class="form-control" type="text" name="legalName" id="legalName">
                    </div>

                    <div class="form-group">
                      <label for="country">Is the transferee claiming an exemption?
                        <a class="text-tertiary collapse-toggle" href="#claimingExemtionHelp" data-toggle="collapse" aria-expanded="false" aria-controls="claimingExemtionHelp">
                          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                        </a>
                        <div class="collapse" id="claimingExemtionHelp">
                          <div class="card bg-secondary my-1">
                            <div class="card-body">
                              If you are claiming an exemption, then choose an <a href="https://www2.gov.bc.ca/gov/content/taxes/property-taxes/property-transfer-tax/file/tax-return-guide/exemption-codes" target="_blank" rel="nofollow noopener">exemption code</a> from the list now. It is not possible to claim an exemption for a transferee after the transferee has been added.
                            </div>
                          </div>
                        </div>
                      </label>
                      <select class="form-control" name="corpClaimingExeption" id="corpClaimingExeption">
                        <option>None - No exemption claimed</option>
                        <option>03 - Transitional Rate of Tax</option>
                        <option>04 - Transitional Rate of Tax</option>
                        <option>05 - Related Individual – Principal Residence</option>
                        <option>06 - Recreational Residence</option>
                        <option>07 - Family Farm</option>
                        <option>08 - Survivorship</option>
                        <option>09 - Executor/Executrix of Estate</option>
                        <option>10 - Subdivision – Multi Lot</option>
                        <option>12 - Trustee / Bankruptcy</option>
                        <option>13 - Vendor's Interest under Agreement for Sale</option>
                        <option>14 - Fee Simple / Agreement for Sale</option>
                        <option>15 - Separation Agreement - Related</option>
                        <option>16 - Changing a Joint Tenant to a Tenancy in Common</option>
                        <option>17 - Lease</option>
                        <option>18 - Family Farm Corporation</option>
                        <option>19 - Escheat or Forfeit to Crown</option>
                        <option>20 - Transfer to Muncipality, Reg. District, etc.</option>
                      </select>
                    </div>

                    <div class="form-actions mt-1 mb-4">
                      <button class="btn btn-md btn-tertiary">Done</button>
                      <button class="btn btn-md btn-link">Cancel</button>
                    </div>
                  </template>

                  <!-- Corporation -->
                  <template v-if="transfereeType == 8">
                    <div class="form-group">
                      <label for="describeType">Describe the Type</label>
                      <input class="form-control" type="text" name="describeType" id="describeType">
                    </div>

                    <div class="form-group">
                      <label for="noneOfAboveName">Legal Corporation Name</label>
                      <input class="form-control" type="text" name="noneOfAboveName" id="noneOfAboveName">
                    </div>

                    <div class="form-group">
                      <label for="country">Is the transferee claiming an exemption?</label>
                      <select class="form-control" name="corpClaimingExeption" id="corpClaimingExeption">
                        <option>None - No exemption claimed</option>
                        <option>03 - Transitional Rate of Tax</option>
                        <option>04 - Transitional Rate of Tax</option>
                        <option>05 - Related Individual – Principal Residence</option>
                        <option>06 - Recreational Residence</option>
                        <option>07 - Family Farm</option>
                        <option>08 - Survivorship</option>
                        <option>09 - Executor/Executrix of Estate</option>
                        <option>10 - Subdivision – Multi Lot</option>
                        <option>12 - Trustee / Bankruptcy</option>
                        <option>13 - Vendor's Interest under Agreement for Sale</option>
                        <option>14 - Fee Simple / Agreement for Sale</option>
                        <option>15 - Separation Agreement - Related</option>
                        <option>16 - Changing a Joint Tenant to a Tenancy in Common</option>
                        <option>17 - Lease</option>
                        <option>18 - Family Farm Corporation</option>
                        <option>19 - Escheat or Forfeit to Crown</option>
                        <option>20 - Transfer to Muncipality, Reg. District, etc.</option>
                      </select>
                    </div>

                    <div class="form-actions mt-1 mb-4">
                      <button class="btn btn-md btn-tertiary">Done</button>
                      <button class="btn btn-md btn-link">Cancel</button>
                    </div>
                  </template>
                </form>
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'
import DatePicker from '../../../components/DatePicker'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel,
  },

  data () {
    return {
      transfereeType: 0,
      transfereeFormOpen: false,
      canadianCitizen: false
    }
  },
  methods: {

  }
}
</script>

